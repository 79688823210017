export const BYPASS_SERVER_CACHE = false;

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyApUekZMZX8CJbg_ukqbN7UeLhmvt_C5oQ',
  authDomain: 'prestokast.firebaseapp.com',
  databaseURL: 'https://prestokast.firebaseio.com',
  projectId: 'prestokast',
  storageBucket: 'prestokast.appspot.com',
  messagingSenderId: '1083659034942',
  appId: '1:1083659034942:web:e3ff72df4f647b011fcc6f',
  measurementId: 'G-L2N62V1RVP',
  publicVapidKey: 'BE5RNR4fxrzg4WSAmKqr4AMX_Vl5znIqqfagzB9pVfqb4C9r_jzxMFiPRsPLwqu5py6vfTuHDUZ_FEkfTezx7lw',
};

export const APP_VERSION = {
  number: 0.95,
  suffix: '-beta.1',
  releaseDate: '16-05-2021',
};

export const AMAZON_ASSOCIATES = {
  UK: (query: string) =>
    `https://www.amazon.co.uk/s/ref=as_li_ss_tl?k=${query}&crid=1SV2HNJYW6DPO&ref=nb_sb_ss_i_1_12&linkCode=ll2&tag=prestokast-21&linkId=27666faa17b24917970398e91230f37d&language=en_GB`,
  US: (query: string) =>
    `https://www.amazon.com/s/ref=as_li_ss_tl?k=${query}&crid=NV9EIZQTVGS0&ref=nb_sb_ss_i_1_12&linkCode=ll2&tag=prestokast-20&linkId=de6bd89ecc42bc8f89fae5e34efd0405&language=en_US`,
};

export const COPYRIGHT = '© 2021 PrestoKast LTD, Presto Group LTD, or its affiliates';

export const IPIFY_API = {
  key: 'at_9ZaumHJUmCxEPGcu3RdwtuUOJJGU3',
  url: 'https://geo.ipify.org/api/v1?',
};

export const _SHARED_GLOBALS = {
  firebaseConfig: FIREBASE_CONFIG,
  version: APP_VERSION,
  BYPASS_SERVER_CACHE,
  IPIFY_API,
  IMAGES_BUCKET_NAME: 'https://storage.googleapis.com/prestokast.appspot.com',
  EARLY_ACCESS_CODE: 'jaijaithejetplane',
};
