const PREFIXES = {
    playlist: 'pls_',
    group: 'grp_',
    show: 'shw_',
    episode: 'epi_',
    audioSession: 'aus_',
    support: 'sup_',
    transcript: 'tcp_',
    log: 'log_',
    person: 'psn_',
    report: 'rpt_',
    comment: 'cmt_',
    review: 'rvw_',
    image: 'img_',
    job: 'job_',
    write: 'wrt_',
    toReview: 'trw_'
} as const;
type IdType = keyof typeof PREFIXES;
function dec2hex (dec: any) {
    return ('0' + dec.toString(16)).substr(-2);
}

export type GeneratePKID = typeof generatePKID;
/**
 * Generate an ID for new database document.
 * @param type Type of data to be stored; this ensures the correct prefix is assigned (eg pls_d213DJw92msIWOHc203 for payments).
 * If no type is given, no prefix is assigned.
 * @param chars Number of characters in the ID after the prefix. Defaults to 10.
 */
export function generatePKID(type: IdType, chars: 10 | 20 | 30 = 10, useCrypto = true) {
    if (!useCrypto) return `${PREFIXES[type] || ''}${[...Array(chars)].map(() => Math.random().toString(36)[2]).join('')}`;

    let token = '';
    if (typeof window === 'undefined' && typeof process !== 'undefined') {
        const crypto = require('crypto');
        token = crypto.randomBytes(chars / 2).toString('hex');
    }
    else if (typeof window !== 'undefined') {
        const crypto = (window.crypto || (window as any).msCrypt);
        const arr = new Uint8Array(chars / 2);
        crypto.getRandomValues(arr);
        token = Array.from(arr, dec2hex).join('');
    }
    else throw Error('crypto is not available. Either run in environment with crypto available, or set useCrypto=false.');

    return `${PREFIXES[type] || ''}${token}`;
}

/**
 * Generate a random string of numbers. Under the hood: A random number is multiplied by a quadrillion, then trimmed to 10
 * characters or otherwise.
 * @param chars Character length of string to generate. Defaults to 10.
 */
export function genRandomNumberString(chars: 5 | 10 | 15 = 10) {
    return `${Math.floor(Math.random() * 100000000000000)}`.substring(0, chars);
}

export function extractPKID(path: string) {
    return path.split(path.includes('~') ? '~' : '/').pop();
}

/**
 * Determine the entity type by its ID.
 * @param entityId ID of the entity.
 */
 export const determineEntityFromId = (entityId: string) => {
    switch (entityId.split(entityId.includes(':') ? ':' : '_')[0]) {
        case 'cpl': return 'completed';
        case 'pld': return 'played';
        case 'rvw': return 'review';
        case 'bkg': return 'bookmark-group';
        case 'bkc': return 'bookmark';
        case 'epi': return 'episode';
        case 'psn': return 'person';
        case 'tpc': return 'topic';
        case 'shw': return 'show';
        case 'mtn': return 'mention';
        case 'flw': return 'follow';
        case 'cmt': return 'comment';
        default: return null;
    }
}
