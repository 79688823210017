import { Timestamp } from 'src/app/@core/services/db.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom, Observable, timer } from 'rxjs';
import { first, shareReplay, switchMap } from 'rxjs/operators';

import { Plugins, Capacitor } from '@capacitor/core';
import { SpeedTestService } from 'ng-speed-test';
import { environment } from 'src/environments/environment';

const { Device, Network } = Plugins;
export interface GeoIP {
  as: {
    asn: number;
    domain: string;
    name: string;
    route: string;
    type: string;
  };
  domains: string[];
  ip: string;
  location: {
    country: string;
    region: string;
    city: string;
    lat: number;
    lng: number;
    postalCode: string;
    timezone: string;
    geonameId: number;
  };
}

@Injectable({ providedIn: 'root' })
export class DeviceService {
  network = {} as {
    connected: boolean;
    connectionType: string;
    mbps: number;
  };
  checkingForUpdate: boolean;
  updateAvailable: boolean;
  todaysDate = Timestamp(true);

  /**
   * Interval observable for assessing internet speed every ten minutes.
   */
  internetSpeedCheckTimer$ = timer(0, 1000 * 60 * 10);

  /**
   * The host device's name.
   */
  name: string;

  /**
   * Detected operating system.
   */
  os: 'Windows' | 'Macintosh' | 'Linux' | 'Android' | 'iOS';

  constructor(public iPlatform: Platform, private http: HttpClient, private speed: SpeedTestService) {
    this.init();
  }

  platforms = this.iPlatform.platforms().concat(window.location.protocol !== 'file' ? 'browser' : []);

  geoIP$ = this.http
    .get(environment.IPIFY_API.url, { params: { apiKey: environment.IPIFY_API.key, noProxy: 'true' } })
    .pipe(shareReplay()) as Observable<GeoIP>;

  private async init() {
    try {
      this.internetSpeedCheckTimer$
        .pipe(switchMap(() => this.getInternetSpeed()))
        .subscribe((speed) => (this.network.mbps = speed));

      if (navigator.userAgent.indexOf('Win') != -1) this.os = 'Windows';
      else if (navigator.userAgent.indexOf('Mac') != -1) this.os = 'Macintosh';
      else if (navigator.userAgent.indexOf('Linux') != -1) this.os = 'Linux';
      else if (navigator.userAgent.indexOf('Android') != -1) this.os = 'Android';
      else if (navigator.userAgent.indexOf('like Mac') != -1) this.os = 'iOS';

      const platforms = this.iPlatform.platforms();
      this.name = platforms[0] || `unknown-${new Date().valueOf()}`;

      const { connected, connectionType } = await Network.getStatus();
      this.network.connected = connected;
      this.network.connectionType = connectionType;
      const et = (navigator as any)?.connection?.effectiveType;
      if (et) {
        this.network.connectionType = et;
      }
      Network.addListener('networkStatusChange', ({ connected, connectionType }) => {
        this.network.connected = connected;
        this.network.connectionType = connectionType;
        console.log('Network Status Changed:', status);
        const et = (navigator as any)?.connection?.effectiveType;
        if (et) {
          this.network.connectionType = et;
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  private _platform: 'web' | 'android' | 'ios';

  get platform() {
    if (!this._platform) this._platform = Capacitor.getPlatform() as any;
    return this._platform;
  }

  get isFileSystemAvailable() {
    return this.platform !== 'web';
  }

  /**
   * Test the internet connection speed of the host device. By default, three tests will be run and the rounded average will be returned.
   *
   * @param iterations Number of requests to run in order to get a reliable results (defaults to three).
   * @returns Speed in mbps.
   */
  getInternetSpeed(iterations = 3) {
    return lastValueFrom(
      this.speed.getMbps({
        iterations,
      })
    );
  }

  private preventNavigation() {
    const location = window.document.location;
    const originalHashValue = location.hash;

    window.setTimeout(() => {
      location.hash = 'preventNavigation' + 9999 * Math.random();
      location.hash = originalHashValue;
    }, 0);
  }

  disableBrowserNavigation() {
    window.addEventListener('beforeunload', this.preventNavigation, false);
    window.addEventListener('unload', this.preventNavigation, false);
  }

  enableBrowserNavigation() {
    window.removeEventListener('beforeunload', this.preventNavigation);
    window.removeEventListener('unload', this.preventNavigation);
  }

  loadUpdate() {
    window.location.reload();
  }

  get screen() {
    return window.screen.width < 768 ? 'mobile' : 'desktop';
  }
}
