import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
  FormatDatePipe,
  FormatTimePipe,
  SecsToHHMMSSPipe,
  FormatDurationPipe,
  ObjValuesPipe,
  CamelToTitlePipe,
} from './utils/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangeEventsDirective } from './utils/directives';
import { DividerModule } from '../elements/divider/divider.module';
import { ImageComponent } from '../elements/image/image.component';
import { BackButtonModule } from '../elements/back-button/back-button.module';
import { LoadingModule } from '../elements/loading/loading.module';
import { RouterModule } from '@angular/router';
import { Autostart } from '@ionic-native/autostart/ngx';
import { ItemButtonModule } from '../elements/item-button/item-button.module';

@NgModule({
  declarations: [
    FormatDatePipe,
    FormatTimePipe,
    RangeEventsDirective,
    SecsToHHMMSSPipe,
    FormatDurationPipe,
    ObjValuesPipe,
    ImageComponent,
    CamelToTitlePipe,
  ],
  imports: [
    IonicModule,
    CommonModule,
    DividerModule,
    BackButtonModule,
    FormsModule, // Included globally only for overlays (TODO?: better method needed)
    ReactiveFormsModule,
    LoadingModule, // Included globally only for overlays (TODO?: better method needed)
    RouterModule,
    ItemButtonModule,
  ],
  exports: [
    IonicModule,
    CommonModule,
    FormatDatePipe,
    FormatTimePipe,
    RangeEventsDirective,
    SecsToHHMMSSPipe,
    FormatDurationPipe,
    ObjValuesPipe,
    DividerModule,
    ImageComponent,
    BackButtonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    CamelToTitlePipe,
    RouterModule,
    ItemButtonModule,
  ],
  providers: [Autostart],
})
export class CoreModule {}
