import { Injectable } from '@angular/core';

import { DeviceService } from '../services/device.service';
import { OverlaysService } from '../services/overlays.service';

@Injectable({ providedIn: 'root' })
export class BackButtonGuard {
  constructor(private device: DeviceService, private overlays: OverlaysService) {}

  async canDeactivate() {
    if (!this.device.platforms.includes('browser')) return true;
    return !(await this.overlays.closeTop());
  }
}
