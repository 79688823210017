import { Injectable } from '@angular/core';
import { RouterService } from '../@core/services/router.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private router: RouterService) {}

  isOpen() {
    return this.router.url.includes('(ol:settings');
  }

  open() {
    return this.router.ngRouter.navigate([{ outlets: { ol: ['settings'] } }], {
      queryParamsHandling: 'preserve',
    });
  }
}
