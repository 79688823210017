import { Injectable } from '@angular/core';
import { ToastOptions } from '@ionic/core';
import { ToastController } from '@ionic/angular';

type Options = ToastOptions | string;

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toast: HTMLIonToastElement;
  private defaultOpts: ToastOptions = {
    duration: 5000,
    position: 'bottom',
    buttons: [
      {
        icon: 'close',
        role: 'cancel',
      },
    ],
  };
  private isPresent: boolean;

  constructor(private toastCtrl: ToastController) {
    this.init();
  }

  private async init() {
    this.toast = await this.toastCtrl.create();
  }

  async present(options?: Options) {
    if (this.isPresent) await this.toast.onDidDismiss();

    if (
      options &&
      typeof options === 'object' &&
      options.buttons?.findIndex((x: any) => x.icon === 'close' || x.role === 'cancel') === -1
    )
      options.buttons = [...(options.buttons || []), { icon: 'close', role: 'cancel' }];

    if (!this.toast || options)
      this.toast = await this.toastCtrl.create(
        typeof options === 'string' ? { ...this.defaultOpts, message: options } : { ...this.defaultOpts, ...options }
      );

    await this.toast.present();
    this.isPresent = true;
    this.toast.onDidDismiss().then(() => (this.isPresent = false));
    return this.toast;
  }

  changesSaved() {
    this.present({ message: 'Changes saved' });
  }

  error(options?: Options) {
    if (options && typeof options === 'string') {
      return this.present({
        header: 'Ooops.',
        color: 'danger',
        message: options,
      });
    } else
      return this.present({
        header: 'Ooops.',
        color: 'danger',
        message: 'Something went wrong - check the logs.',
        ...(options as any),
      });
  }

  async dismiss(id?: string) {
    await this.toast.dismiss(id);
    return this.init();
  }
}
