import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import Color from 'color';
import { StorageService } from './storage.service';

const getColorContrast = (color, ratio = 0.8) => {
  const c = Color(color);
  return (c.isDark() ? c.lighten(ratio) : c.darken(ratio)).hex();
};

const toRGBString = (color) => Color(color).rgb().array().toString();

const DEFAULTS = {
  primary: '#3880ff',
  secondary: '#0cd1e8',
  tertiary: '#7044ff',
  dark: '#222428',
  medium: '#989aa2',
  light: '#f4f5f8',
  success: '#10dc60',
  warning: '#ffce00',
  danger: '#f04141',
  background: 'white',
  text: 'black',
  border: '#d9d9d9',
};

const THEMES = {
  grey: {
    ...DEFAULTS,
    background: getColorContrast(DEFAULTS.background, 0.6),
    text: getColorContrast(DEFAULTS.background, 0.1),
    border: getColorContrast(DEFAULTS.border, 0.3),
  },
  sepia: {
    ...DEFAULTS,
    background: '#ffefcc',
    text: 'black',
    border: getColorContrast(DEFAULTS.border, 0.5),
  },
};

export type Theme = 'dark' | 'light' | 'grey' | 'sepia' | 'auto';

const genCSSText = (theme: Theme) => {
  const {
      primary,
      secondary,
      tertiary,
      dark,
      medium,
      light,
      success,
      danger,
      warning,
      background,
      text,
      border,
    } = THEMES[theme],
    ratioShade = 0.1,
    ratioTint = 0.1;

  return `
    --ion-background-color: ${background};
    --ion-text-color: ${text};
    --ion-border-color: ${border}

    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: ${toRGBString(primary)};
    --ion-color-primary-contrast: ${getColorContrast(primary)};
    --ion-color-primary-contrast-rgb: ${toRGBString(primary)};
    --ion-color-primary-shade:  ${Color(primary).darken(ratioShade)};
    --ion-color-primary-tint:  ${Color(primary).lighten(ratioTint)};

    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${toRGBString(secondary)};
    --ion-color-secondary-contrast: ${getColorContrast(secondary)};
    --ion-color-secondary-contrast-rgb: ${toRGBString(secondary)};
    --ion-color-secondary-shade:  ${Color(secondary).darken(ratioShade)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(ratioTint)};

    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: ${toRGBString(tertiary)};
    --ion-color-tertiary-contrast: ${getColorContrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: ${toRGBString(tertiary)};
    --ion-color-tertiary-shade: ${Color(tertiary).darken(ratioShade)};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(ratioTint)};

    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${toRGBString(dark)};
    --ion-color-dark-contrast: ${getColorContrast(dark)};
    --ion-color-dark-contrast-rgb: ${toRGBString(dark)};
    --ion-color-dark-shade: ${Color(dark).darken(ratioShade)};
    --ion-color-dark-tint: ${Color(dark).lighten(ratioTint)};

    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${toRGBString(medium)};
    --ion-color-medium-contrast: ${getColorContrast(medium)};
    --ion-color-medium-contrast-rgb: ${toRGBString(medium)};
    --ion-color-medium-shade: ${Color(medium).darken(ratioShade)};
    --ion-color-medium-tint: ${Color(medium).lighten(ratioTint)};

    --ion-color-light: ${light};
    --ion-color-light-rgb: ${toRGBString(light)};
    --ion-color-light-contrast: $${getColorContrast(light)};
    --ion-color-light-contrast-rgb: ${toRGBString(light)};
    --ion-color-light-shade: ${Color(light).darken(ratioShade)};
    --ion-color-light-tint: ${Color(light).lighten(ratioTint)};

    --ion-color-success: ${success};
    --ion-color-success-rgb: ${toRGBString(success)};
    --ion-color-success-contrast: ${getColorContrast(success)};
    --ion-color-success-contrast-rgb: ${toRGBString(success)};
    --ion-color-success-shade: ${Color(success).darken(ratioShade)};
    --ion-color-success-tint: ${Color(success).lighten(ratioTint)};

    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${toRGBString(warning)};
    --ion-color-warning-contrast: ${getColorContrast(warning)};
    --ion-color-warning-contrast-rgb: ${toRGBString(warning)};
    --ion-color-warning-shade: ${Color(warning).darken(ratioShade)};
    --ion-color-warning-tint: ${Color(warning).lighten(ratioTint)};

    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${toRGBString(danger)};
    --ion-color-danger-contrast: ${getColorContrast(danger)};
    --ion-color-danger-contrast-rgb: ${toRGBString(danger)};
    --ion-color-danger-shade: ${Color(danger).darken(ratioShade)};
    --ion-color-danger-tint: ${Color(danger).lighten(ratioTint)};
    `;
};
@Injectable({ providedIn: 'root' })
export class ThemeService {
  list = THEMES;
  active: Theme;

  private prefersColorSchemeLight = window.matchMedia('(prefers-color-scheme: light)');
  private prefersColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(@Inject(DOCUMENT) private document: Document, private storage: StorageService) {
    this.init();
  }

  private async init() {
    await this.setTheme((await this.storage.get('theme')) || 'dark');
    this.prefersColorSchemeLight.onchange = (e) =>
      this.active === 'auto' && e.matches ? this.setTheme('light') : false;
    this.prefersColorSchemeDark.onchange = (e) => (this.active === 'auto' && e.matches ? this.setTheme('dark') : false);
  }

  checkDeviceThemePreference(preference: 'light' | 'dark') {
    return preference === 'light' ? this.prefersColorSchemeLight.matches : this.prefersColorSchemeDark.matches;
  }

  private storeLocal(theme: Theme) {
    return this.storage.storage.set('theme', theme);
  }

  private clearLocal() {
    return this.storage.storage.remove('theme');
  }

  setTheme(theme: Theme) {
    if (theme === 'auto') {
      if (this.checkDeviceThemePreference('light')) this.setTheme('light');
      else if (this.checkDeviceThemePreference('dark')) this.setTheme('dark');
      if (this.active) this.applyCSS('');
    } else if (theme === 'dark') {
      document.body.classList.toggle('dark', true);
      if (this.active) this.applyCSS('');
    } else if (theme === 'light') {
      document.body.classList.toggle('dark', false);
      if (this.active) this.applyCSS('');
    } else {
      document.body.classList.toggle('dark', false);
      if (this.active) this.applyCSS(genCSSText(theme));
    }

    this.active = theme;
    return this.storeLocal(theme);
  }

  private applyCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }
}
