import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class PremiumUserGuard implements CanActivate {
  constructor(private user: UserService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      await lastValueFrom(
        this.user.data$.pipe(
          map(({ plan }) => plan === 'premium'),
          first()
        )
      )
    )
      return true;
    return this.router.parseUrl('/');
  }
}
