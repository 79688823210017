import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Collection } from 'configs/refs';
import { lastValueFrom } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { generatePKID } from 'utils/functions/id';
import { AppService } from './app.service';
import { DbService, Timestamp } from './db.service';
import { DeviceService } from './device.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  location: Location;
  user: UserService;
  device: DeviceService;
  app: AppService;

  constructor(private db: DbService, private injector: Injector) {}

  async logError({ stack, error }) {
    if (!this.location) this.location = this.injector.get(Location);
    if (!this.user) this.user = this.injector.get(UserService);
    if (!this.device) this.device = this.injector.get(DeviceService);
    if (!this.app) this.app = this.injector.get(AppService);

    return this.db.updateDoc(`${Collection.Reports}/${generatePKID('report')}`, {
      created: Timestamp(),
      object: 'report',
      type: 'error',
      app: {
        releaseDate: this.app.releaseDate,
        version: this.app.version,
      },
      user: {
        uid: this.user.uid,
        name: this.user.name,
        email: this.user.loginEmail,
      },
      device: {
        info: this.user.deviceSettings,
        platforms: this.device.platforms,
        screen: this.device.screen,
        geo: await lastValueFrom(this.device.geoIP$.pipe(first())),
      },
      event: {
        stack,
        url: this.location?.path(true),
        message: error.message || error.toString(),
      },
    });
  }
}
