import { Component, OnInit, ElementRef, ViewChild, AfterContentInit, HostListener, Injector } from '@angular/core';
import { Platform, GestureController, MenuController, AlertController } from '@ionic/angular';
import { UserService } from './@core/services/user.service';
import { StorageService } from './@core/services/storage.service';
import { AuthService } from './@core/services/auth.service';
import { FileSystemService } from './@core/services/filesystem.service';
import { AppService } from './@core/services/app.service';
import { DeviceService } from './@core/services/device.service';
import { ThemeService } from './@core/services/theme.service';
import { environment } from 'src/environments/environment';
import { LatestService } from './latest/latest.service';
import { AdminService } from './@core/services/admin.service';
import { SettingsService } from './settings/settings.service';
import { NotificationsService } from './@core/services/notifications.service';
import { Plugins, Capacitor } from '@capacitor/core';
import { first, pluck } from 'rxjs/operators';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { RouterService } from './@core/services/router.service';
import { COPYRIGHT } from 'src/environments/config';
import { PlayerService } from './player/player.service';

const { SplashScreen } = Plugins;

export interface Button {
  label?: string;
  color?: string;
  icon?: string;
  press: Function;
  disabled?: boolean;
  hidden?: boolean;
  routerPath?: string;
  slot?: string;
  fill?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  @ViewChild('adminGhostBtn', { read: ElementRef, static: true }) adminGhostBtnEl: ElementRef;
  copyrightInfo = COPYRIGHT;
  menuBtns: Button[] = [
    { label: 'Help', icon: 'help', press: () => this.router.go('help') },
    // { label: 'Friends', function: () => this.router.go('friends'), shouldHide: () => true }
  ];

  constructor(
    public fs: FileSystemService,
    public platform: Platform,
    public user: UserService,
    public auth: AuthService,
    public storage: StorageService,
    public app: AppService,
    public device: DeviceService,
    public theme: ThemeService,
    public latest: LatestService,
    public router: RouterService,
    public gestureCtrl: GestureController,
    public menuCtrl: MenuController,
    public admin: AdminService,
    public settings: SettingsService,
    public notifications: NotificationsService,
    private injector: Injector,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();
  }

  @HostListener('click', ['$event']) press(event) {
    this.app.click$.next(event);
  }

  // @HostListener('document:keyup', ['$event']) onKeydown(event) {
  //   const node = event.srcElement?.nodeName;
  //   if (node !== 'INPUT' && node !== 'TEXTAREA') {
  //     if (event.key === 'f') this.app.enableFullscreen();
  //     else if (event.key === 'p') this.injector.get(PlayerService).togglePlayback();
  //     else if (event.key === 'h') this.router.go('home');
  //     else if (event.key === 's') this.router.go('search');
  //     else if (event.key === 'l') this.router.go('library');
  //     else if (event.key === 'Backspace') this.router.back();
  //   }
  // }

  ngOnInit() {}

  async ngAfterContentInit() {
    const adminBtn = this.adminGhostBtnEl.nativeElement;
    const adminAccessGesture = this.gestureCtrl.create({
      el: adminBtn,
      gestureName: 'admin-access',
      gesturePriority: 100,
      direction: 'y',
      onStart: (ev) => {},
      onMove: (ev) => {},
      onEnd: (ev) => {
        if (ev.deltaY < -100) {
          if (!this.user.roles.includes('admin')) this.admin.openLogin();
          else this.admin.openLogout();
          this.menuCtrl.close();
        }
      },
    });
    adminAccessGesture.enable();
  }

  async initializeApp() {
    const betaAuth = await this.alertCtrl.create({
      backdropDismiss: false,
      header: 'Early Access',
      message: `Please enter your Early Access code. To request a code, email us at 'contact@prestokast.com'.`,
      inputs: [
        {
          type: 'text',
          name: 'code',
          label: ' Code',
        },
      ],
      buttons: [
        {
          text: 'Continue',
          handler: ({code}) => {
            console.log(code);
            if (code === environment.EARLY_ACCESS_CODE) return true;

            return false;
          },
        },
      ],
    });

    await betaAuth.present();
    await betaAuth.onDidDismiss();

    await this.platform.ready();
    if (Capacitor.isPluginAvailable('SplashScreen')) SplashScreen.hide();

    if (await lastValueFrom(this.user.deviceSettings$().pipe(pluck('preferences', 'fullscreen'), first()))) {
      await firstValueFrom(this.app.click$);
      this.app.enableFullscreen();
    }
  }

  async menuBtnClickEvent(f?: Function) {
    if (!f) return false;
    await this.menuCtrl.close();
    f();
  }

  openSettings() {
    return this.settings.open();
  }

  get isDevMode() {
    return !environment.production;
  }

  trackByLabel(index: number, btn: Button) {
    return btn.label;
  }

  overlayBackdropClickEvent(e) {
    return this.router.navigate([{ outlets: { ol: null } }], {
      queryParamsHandling: 'preserve',
    });
  }

  goToPlans() {
    this.router.go('plans');
  }
}
