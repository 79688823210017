import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RedirectService } from '../services/redirect.service';

@Injectable({ providedIn: 'root' })
export class UserOnlyGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private redirect: RedirectService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!(await lastValueFrom(this.auth.user$.pipe(first())))) return true;
    else {
      // if(!state.url.includes('__SKIP_REDIRECT__'))this.redirect.redirectTo = state.url
      this.router.navigateByUrl('/login', { skipLocationChange: true });
      return false;
    }
  }
}
