import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AlertController } from '@ionic/angular';
import { ToastService } from './toast.service';
import { DbService } from './db.service';
import { map, first } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(
    private user: UserService,
    private alertCtrl: AlertController,
    private toast: ToastService,
    private db: DbService,
    private storage: StorageService
  ) {
    this.init();
  }

  private async init() {
    const adminUser = await this.storage.get('admin');
    if (adminUser) {
      try {
        this.login(adminUser);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async openLogin(options?, isRetry = false) {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert login',
      header: 'Admin Login',
      message: 'Please enter your admin credentials.',
      inputs: [
        {
          name: 'user',
          placeholder: 'Email / UID',
        },
      ],
      buttons: [
        {
          cssClass: 'close-btn',
          text: 'Cancel',
        },
        {
          cssClass: 'login-btn',
          text: 'Login',
          handler: async ({ user }) => {
            if (!user)
              return this.openLogin(
                { message: 'Please enter some credentials!', cssClass: 'alert error' } as any,
                true
              );
            await this.login(user);
            const toast = await this.toast.present({
              header: `Welcome, ${this.user.name}`,
              message: 'Admin privileges have been enabled.',
              position: 'top',
              color: 'tertiary',
              duration: 5000,
            });
            toast.present();
            try {
            } catch (e) {
              console.error(e);
              this.openLogin({ subHeader: `Error: ${e.code}`, message: e.message, cssClass: 'alert error' }, true);
            }
          },
        },
      ],
      ...options,
    });
    alert.present();
  }

  async openLogout() {
    const logout = await this.alertCtrl.create({
      header: 'Admin Logout',
      message: 'Disable administrator priveleges?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: () => this.logout(),
        },
      ],
    });
    logout.present();
  }

  private async login(user: string) {
    if (
      (
        await lastValueFrom(
          this.user.data$.pipe(
            map(({ roles }) => roles),
            first()
          )
        )
      ).includes('admin')
    ) {
      if (await lastValueFrom(this.db.doc$(`administrators/${this.user.uid}`).pipe(first()))) {
        if (user === this.user.uid || user === this.user.loginEmail || user === this.user.contactEmail) {
          await this.storage.set('admin', user);
        } else
          throw {
            code: 2,
            message: 'Provided email/uid does not match the current user.',
          };
      } else
        throw {
          code: 1,
          message:
            "Admin account not found: User has necessary roles but admin credentials weren't found. Make sure the admin account has been created.",
        };
    } else throw { code: 1, message: 'This user does not have admin permission - check the roles.' };
  }

  private async logout() {
    await this.storage.storage.remove('admin');
    await this.toast.present({
      message: 'Admin privileges have been disabled.',
      position: 'top',
      color: 'medium',
      duration: 5000,
    });
  }
}
