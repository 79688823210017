import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackButtonGuard } from './@core/router-guards/back-button.guard';
import { UserOnlyGuard } from './@core/router-guards/user-only.guard';
import { UserRedirectGuard } from './@core/router-guards/user-preload.guard';
import { PremiumUserGuard } from './@core/router-guards/premium-user.guard';
import { NonUserOnlyGuard } from './@core/router-guards/non-user-only.guard';

export const OVERLAY_ROUTES: Routes = [
  {
    path: 'show/:id',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./show/show-details/show-details.module').then((m) => m.ShowDetailsModule),
  },
  {
    path: 'episode/:id',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./episode/episode-details/episode-details.module').then((m) => m.EpisodeDetailsModule),
  },
  {
    path: 'person/:id',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./person/person-details/person-details.module').then((m) => m.PersonDetailsModule),
  },
  {
    path: 'playlist/:id',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./playlist/playlist.module').then((m) => m.PlaylistComponentModule),
  },
  {
    path: 'group/:id',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./group/group.module').then((m) => m.GroupComponentModule),
  },
  {
    path: 'settings',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'about-app',
    canActivate: [UserOnlyGuard],
    outlet: 'ol',
    loadChildren: () => import('./about-app/about-app.module').then((m) => m.AboutAppModule),
  },
  {
    path: 'bookmarks/:id',
    canActivate: [UserOnlyGuard, PremiumUserGuard],
    outlet: 'ol',
    loadChildren: () => import('./bookmarks/bookmark-clips/bookmark-clips.module').then((m) => m.BookmarkClipsModule),
  },
  {
    path: 'entity-info/:id',
    canActivate: [UserOnlyGuard, PremiumUserGuard],
    outlet: 'ol',
    loadChildren: () => import('./elements/entity-info/entity-info.module').then((m) => m.EntityInfoModule),
  },
  {
    path: 'stats/:id',
    canActivate: [PremiumUserGuard],
    outlet: 'ol',
    loadChildren: () => import('./stats/stats-routing.module').then((m) => m.StatsRoutingModule),
  },
];

export const ROUTES: Routes = [
  ...OVERLAY_ROUTES,
  {
    path: '',
    canActivate: [NonUserOnlyGuard],
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./splash/splash.module').then((m) => m.SplashPageModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'login',
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'splash',
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./splash/splash.module').then((m) => m.SplashPageModule),
  },
  {
    path: 'show',
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./show/show.module').then((m) => m.ShowPageModule),
  },
  {
    path: 'episode',
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./episode/episode.module').then((m) => m.EpisodePageModule),
  },
  {
    path: 'help',
    canActivate: [UserRedirectGuard],
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./help/help.module').then((m) => m.HelpPageModule),
  },
  {
    path: 'support',
    canActivate: [UserRedirectGuard],
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./support/support.module').then((m) => m.SupportPageModule),
  },

  {
    path: 'u',
    canActivate: [UserOnlyGuard],
    canDeactivate: [BackButtonGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      // preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
      // urlUpdateStrategy: 'deferred',
      // paramsInheritanceStrategy: 'always',
      // enableTracing: true,
      anchorScrolling: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
