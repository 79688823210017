import { Injectable, Injector } from '@angular/core';
import {
  UserDocument,
  SubscriptionPlanId,
  UserAccountDocument,
  SubscriptionDocument,
  DeviceSettings,
  Collection,
  Trigger,
} from 'utils/types';
import { AuthService } from './auth.service';
import { switchMap, shareReplay, filter, tap, retryWhen, delayWhen, map, first } from 'rxjs/operators';
import { DbService, DeleteField } from './db.service';
import { of, Observable, throwError, timer, lastValueFrom, Subject, from, BehaviorSubject } from 'rxjs';
import { DocumentReference, QueryFn } from '@angular/fire/firestore/interfaces';
import { DeviceInfo, Plugins } from '@capacitor/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { Optional } from 'beautilities';
import { DeviceService } from './device.service';

const getDeviceInfo = async () => ({
  ...(await Plugins.Device.getInfo()),
  // ...await Plugins.Device.getBatteryInfo(),
  language: await Plugins.Device.getLanguageCode(),
});

@Injectable({ providedIn: 'root' })
export class UserService {
  private deviceInfo$ = new BehaviorSubject<DeviceInfo>(null);
  name: string;
  contactEmail: string;
  plan: SubscriptionPlanId;
  roles: string[];
  isNewUser: boolean;
  deviceSettings: DeviceSettings;

  constructor(
    private auth: AuthService,
    private db: DbService,
    private injector: Injector,
    private device: DeviceService
  ) {}

  /* ------------------- Methods - START ------------------- */
  /**
   * Create a new user. This will:
   * * Create a new User Document in the database.
   * * Create an Account Document in the *accounts* subcollection,
   * * Create a Stripe ID and store it on the user's claims.
   * * Send an email to the user for password creation.
   * If successful, a message is returned. Else an error is thrown.
   *
   * @param email Email to use for login. Another email can be added later in Settings.
   * @param name (optional)
   * @returns Success message.
   */
  async create(email: string, name: string) {
    return lastValueFrom(
      this.injector.get(AngularFireFunctions).httpsCallable('createUser')({
        email,
        name,
        device: await getDeviceInfo(),
        TEST: environment.production === false,
      })
    ) as Promise<string>;
  }

  update(data: Optional<UserDocument>) {
    return this.db.updateDoc(this.documentPath, data);
  }

  updateCollection(subpath: string, data: any) {
    return this.db.updateDoc(`${this.documentPath}/${subpath}`, data);
  }

  async updateDeviceData(update: Optional<DeviceSettings>, deviceId?: string) {
    console.log('updateDeviceData');
    return this.update({ devices: { [await this.deviceId]: update } });
  }
  /* ------------------- Methods - END ------------------- */

  /* ------------------- Observables - START ------------------- */
  data$ = this.auth.user$.pipe(
    switchMap((u) => {
      if (!u) return of(null);

      return (this.db.doc$(`${Collection.Users}/${u.uid}`) as Observable<UserDocument>).pipe(
        switchMap((d) =>
          d
            ? of(d).pipe(
                tap(async (d) => {
                  // console.log({u, d});

                  if (d[Trigger.RefreshToken]) {
                    await this.update({ [Trigger.RefreshToken]: DeleteField() } as any);
                    await this.auth.refreshToken();
                  }
                  if (d[Trigger.Logout]) {
                    await this.update({ [Trigger.Logout]: DeleteField() } as any);
                    await this.auth.logout();
                    window.location.reload();
                  }

                  this.name = d.name;
                  this.contactEmail = d.emails[1] || d.emails[0];
                  this.plan = this.db.userPlan = d.plan;
                  this.roles = d.roles;
                  this.deviceSettings = d.devices[this.deviceId];

                  // If deviceInfo is not already stored, get and then compare with database record.
                  // If no record exists, create one.
                  if (!this.deviceInfo$.getValue()) {
                    const deviceInfo = await getDeviceInfo();
                    if (deviceInfo) {
                      this.deviceInfo$.next(deviceInfo);
                      if (!(deviceInfo.uuid in d.devices))
                        await this.update({
                          devices: {
                            [deviceInfo.uuid]: {
                              info: deviceInfo,
                              preferences: {
                                animatedTitles: {
                                  player: this.device.screen === 'desktop',
                                  episodeItems: false,
                                  showItems: false,
                                },
                                backgroundMode: true,
                                fullscreen: false,
                                displayEpisodeThumbs: true,
                                displayShowThumbs: true,
                              },
                            },
                          },
                        });
                      else this.deviceSettings = d.devices[deviceInfo.uuid];
                    }
                  }
                })
              )
            : throwError(
                () => 'Database Error: User is authenticated but no data could not be retreived from the database.'
              )
        )
      );
    }),
    retryWhen((error) =>
      error.pipe(
        tap((e) => {
          // TODO: offline mode, better errors
          // if (!this.isNewUser) console.error(e);
        }),
        delayWhen(() => timer(1000))
      )
    ),
    filter(Boolean),
    tap((x) => {
      console.log('UserDocument', x);
    }),
    shareReplay()
  );

  collection$(collection: string, query?: QueryFn) {
    return this.db.collection$(`${this.documentPath}/${collection}`, query);
  }

  accountDetails$() {
    return this.account$().pipe(
      map((d) => ({
        name: this.name,
        email: this.contactEmail,
        phone: d.phone,
        address: {
          city: d.address.city,
          country: d.address.country,
          postalCode: d.address.postalCode,
          line1: d.address.line1,
          line2: d.address.line2,
          county: d.address.county,
        },
      }))
    );
  }

  deviceSettings$() {
    return this.data$.pipe(map((x) => x.devices[this.deviceId]));
  }

  account$(): Observable<UserAccountDocument> {
    return this.db.doc$(`${this.documentPath}/${Collection.UserAccount}/${this.uid}`);
  }

  subscriptions$(query: QueryFn): Observable<SubscriptionDocument[]> {
    return this.collection$(Collection.Subscriptions, query);
  }
  /* ------------------- Observables - END ------------------- */

  /* ------------------- Getters - START ------------------- */
  get uid() {
    return this.auth.userUID;
  }

  get loginEmail() {
    return this.auth.userLoginEmail;
  }

  get loggedIn() {
    return this.auth.userLoggedIn;
  }

  get documentPath() {
    return `${Collection.Users}/${this.uid}`;
  }

  get ref() {
    return this.db.ref(this.documentPath) as DocumentReference;
  }

  get deviceId() {
    return this.deviceInfo$.getValue()?.uuid;
  }

  getCollectionRef(path: string) {
    return this.db.ref(`${this.documentPath}/${path}`);
  }
  /* ------------------- Getters - END ------------------- */
}
