import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EpisodeDocument, GetFeedResponse, Feed } from 'utils/types';
import { StorageService } from '../@core/services/storage.service';
import { UserService } from '../@core/services/user.service';
import { lastValueFrom } from 'rxjs';
import Parser from 'rss-parser';
import { objToQueryString } from 'functions/node_modules/beautilities';

const parser = new Parser<Feed>({
  requestOptions: {
    rejectUnauthorized: false,
  },
  timeout: 1000 * 60 * 2,
});

@Injectable({ providedIn: 'root' })
export class RSSService {
  constructor(private http: HttpClient, private storage: StorageService, private user: UserService) {}

  get(identifier: string, options = { start: 0, limit: 1000 }) {
    return lastValueFrom(
      this.http.get(
        `https://us-central1-prestokast.cloudfunctions.net/getFeed/?identifier=${objToQueryString(
          {
            identifier,
            ...options,
          },
          undefined,
          { arraySeparator: ',' }
        )}`
      )
    ) as Promise<GetFeedResponse>;
  }

  getLatestEpisode(showId: string, options?: { noCache?: boolean; props?: string[] }) {
    return lastValueFrom(
      this.http.get(
        `https://us-central1-prestokast.cloudfunctions.net/getLatestEpisode/?${objToQueryString(
          {
            showId,
            ...options,
          },
          undefined,
          { arraySeparator: ',' }
        )}`
      )
    ) as Promise<EpisodeDocument>;
  }

  getRaw(url: string) {
    return lastValueFrom(this.http.get(url));
  }

  async getAndTransform(url: string) {
    try {
      const { items, ...feed } = await parser.parseURL(url),
        show = {
          feedUrl: url,
          author: feed.itunes?.author || feed.name || feed.author || feed.host || feed.title || null,
          title: feed.title,
          website: feed.link,
          description: feed.description,
          keywords: [feed.title, feed.author, feed.link, feed.url, ...(feed.itunes?.keywords || [])]
            .filter(Boolean)
            .map((k: string) => k.trim().toLowerCase()),
          feed,
          image: feed.image?.url || feed.itunes?.image,
          categories: [] as string[],
        };
      if (feed.itunes?.categories) show.categories = feed.itunes.categories;

      return show;
    } catch (e) {
      throw e;
    }
  }
}
