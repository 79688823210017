import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { LiteralUnion } from 'beautilities';

type Data = LiteralUnion<'latestServiceCache', string>;

@Injectable({ providedIn: 'root' })
export class StorageService {
  storage: Storage;

  constructor(private ionicStorage: Storage) {
    this.init();
  }

  private async init() {
    if (!this.storage) this.storage = await this.ionicStorage.create();
  }

  async CLEAR() {
    await this.init();
    return this.storage.clear();
  }

  async remove(key: string) {
    await this.init();
    return this.storage.remove(key);
  }

  async get(key: string) {
    await this.init();
    return this.storage.get(key);
  }

  async set(key: Data, data: any) {
    await this.init();
    return this.storage.set(key, data);
  }
}
