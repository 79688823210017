import { Injectable } from '@angular/core';
import { Plugins, FilesystemDirectory, FilesystemEncoding, Capacitor } from '@capacitor/core';
import { DeviceService } from './device.service';
import { ReplaySubject } from 'rxjs';
import { writeFile } from 'capacitor-blob-writer';
import { filter, first } from 'rxjs/operators';
import { UserService } from './user.service';
const { Filesystem } = Plugins;
const rootDirectories = ['saved'];
@Injectable({ providedIn: 'root' })
export class FileSystemService {
  initialised$ = new ReplaySubject<boolean>();

  constructor(private device: DeviceService, user: UserService) {
    user.data$.pipe(filter(Boolean), first()).subscribe(({ uid }) => this.init(uid));
  }

  requestPermissions() {
    return Filesystem.requestPermissions();
  }

  private async isStoragePristine(userUID: string) {
    try {
      await this.readDir(userUID);
    } catch (e) {
      if (e.message.includes('does not exist')) return true;
      else console.error(e);
    }
    return false;
  }

  private async init(userUID: string) {
    if (this.device.isFileSystemAvailable) {
      try {
        if (await this.isStoragePristine(userUID)) {
          await this.mkdir(userUID);
          for (const key of rootDirectories) await this.mkdir(`${userUID}/${key}`);
        }
      } catch (e) {
        console.error('Error at FileSystemService init method.', e);
      }
      this.initialised$.next(true);
    }
  }

  mkdir(path: string) {
    return Filesystem.mkdir({
      path,
      directory: FilesystemDirectory.Data,
      recursive: true,
    });
  }

  writeFile(path: string, data: any) {
    if (path.endsWith('.json') || Capacitor.getPlatform() === 'web')
      return Filesystem.writeFile({
        path,
        directory: FilesystemDirectory.Data,
        data,
        encoding: FilesystemEncoding.UTF8,
      });
    return writeFile({
      path,
      directory: FilesystemDirectory.Data,
      data,
      fallback: (e) => {
        console.error(e);
        return true;
      },
    });
  }

  readFile(path: string) {
    return Filesystem.readFile({
      path,
      directory: FilesystemDirectory.Data,
      encoding: path.endsWith('.json') ? FilesystemEncoding.UTF8 : undefined,
    });
  }

  readDir(path: string) {
    return Filesystem.readdir({
      path,
      directory: FilesystemDirectory.Data,
    });
  }

  getUri(path: string) {
    return Filesystem.getUri({
      directory: FilesystemDirectory.Data,
      path,
    });
  }

  removeFile(path: string) {
    return Filesystem.deleteFile({
      path,
      directory: FilesystemDirectory.Data,
    });
  }

  removeDir(path: string) {
    return Filesystem.rmdir({
      path,
      directory: FilesystemDirectory.Data,
      recursive: true,
    });
  }

  async reset(clear: 'All') {
    if (clear === 'All') for (const dir of (await this.readDir(''))?.files) await this.removeDir(dir);
    else await this.removeDir(clear);
  }
}
