import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap, map, first, tap } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AlertController } from '@ionic/angular';
import { RedirectService } from './redirect.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userLoggedIn: boolean;
  userUID: string;
  userLoginEmail: string;

  constructor(private afAuth: AngularFireAuth, private injector: Injector) {}

  /* ------------------- Methods - START ------------------- */
  createUser(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  loginWithToken(token: string) {
    return this.afAuth.signInWithCustomToken(token);
  }

  loginWithGoogle() {
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    return this.afAuth.signOut();
  }

  async refreshToken() {
    return (await this.afAuth.currentUser).getIdToken(true);
  }

  reCAPTCHA(elementId: string, options?: {}) {
    return new firebase.auth.RecaptchaVerifier(elementId, options);
  }

  sendPasswordResetEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  sendCreatePasswordEmail(email: string) {
    return lastValueFrom(this.injector.get(AngularFireFunctions).httpsCallable('sendCreatePasswordEmail')({ email }));
  }

  sendEmailLoginLink(email: string) {
    return this.afAuth.sendSignInLinkToEmail(email, {
      url: environment.production ? 'https://prestokast.com/u/home' : 'http://localhost:8100/u/home',
      handleCodeInApp: true,
      android: {
        packageName: 'com.PrestoKast.PrestoKast',
        installApp: true,
        minimumVersion: '1',
      },
      dynamicLinkDomain: 'prestokast.page.link',
    });
  }

  async updateEmail(email: string) {
    return (await this.afAuth.currentUser).updateEmail(email);
  }

  reauthenticateUser(password: string, email: string) {
    return this.afAuth.signInAndRetrieveDataWithCredential(firebase.auth.EmailAuthProvider.credential(email, password));
  }

  async updatePassword({
    currentPassword,
    newPassword,
    email,
  }: {
    currentPassword: string;
    newPassword: string;
    email: string;
  }) {
    return Promise.all([
      await this.reauthenticateUser(currentPassword, email),
      (await this.afAuth.currentUser).updatePassword(newPassword),
    ]);
  }

  async openSignupDialog(redirect = true) {
    const dialog = await this.injector.get(AlertController).create({
      id: 'PublicUserSignupMessageComponent',
      cssClass: 'alert public-user-signup-message',
      header: 'Signup to PrestoKast',
      message: `
            To play, like, or interact with media on PrestoKast, you need to be signed in. Signup is free and takes 1 minute!
            `,
      buttons: [
        {
          cssClass: 'close-btn',
          text: 'Cancel',
        },
        {
          text: 'Signup',
          handler: () => {
            if (redirect) this.injector.get(RedirectService).redirectTo = this.injector.get(LocationStrategy).path();
            this.injector.get(Router).navigateByUrl('/signup');
          },
        },
      ],
    });
    dialog.present();
  }
  /* ------------------- Methods - END ------------------- */

  /* ------------------- Getters - START ------------------- */

  getUser() {
    return lastValueFrom(this.user$.pipe(first()));
  }

  getCustomClaims() {
    return lastValueFrom(this.userCustomClaims$.pipe(first()));
  }

  /* ------------------- Getters - END ------------------- */

  /* ------------------- Observable - START ------------------- */

  userCustomClaims$ = this.afAuth.idTokenResult.pipe(
    map((token) => (token ? (token.claims.custom as { [key: string]: any }) : null))
  );

  user$ = this.afAuth.authState.pipe(
    switchMap((cred) => {
      if (!cred) {
        this.userLoggedIn = false;
        return of(null);
      }
      this.userLoggedIn = true;
      this.userUID = cred.uid;
      this.userLoginEmail = cred.email;
      return this.userCustomClaims$.pipe(
        map((claims) => ({
          ...claims,
          ...cred,
        }))
      );
    })
  );
  /* ------------------- Observables - END ------------------- */
}
