import { Injectable } from '@angular/core';
import { MenuController, ModalController, PopoverController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class OverlaysService {
  constructor(
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private router: Router,
    private location: Location
  ) {}

  async isOpen() {
    return !!((await this.getTop()) || this.location.path(true).includes('(ol:'));
  }

  async close(id?: string) {
    return this.modalCtrl
      .dismiss(id)
      .catch((e) => this.popoverCtrl.dismiss(id))
      .catch((e) => this.alertCtrl.dismiss(id));
  }

  async closeTop() {
    const top = await this.getTop();
    if (top) {
      if ('close' in top) return top.close();
      else if ('dismiss') return top.dismiss();
    }
    return false;
  }

  async getTop(type: 'modal' | 'menu' | 'popover' | 'alert' | 'any' = 'any') {
    switch (type) {
      case 'alert':
        return this.alertCtrl.getTop();
      case 'menu':
        return this.getTopMenu();
      case 'modal':
        return this.modalCtrl.getTop();
      case 'popover':
        return this.popoverCtrl.getTop();
      case 'any':
      default:
        return (await this.getTopMenu()) || (await this.getTopComponent());
    }
  }

  getTopMenu() {
    return this.menuCtrl.getOpen();
  }

  async getTopComponent() {
    return (await this.alertCtrl.getTop()) || (await this.popoverCtrl.getTop()) || (await this.modalCtrl.getTop());
  }

  /**
   * Close all open overlays. An options object can be passed to change which overlays are closed. *Primary* overlays
   * are those which are connected to URLs, e.g., '...home(ol:settings)'. *Secondary* overlays are those non connected to
   * URLs and which can only be opened from a user action, e.g., menus, popovers, alerts. By default, only secondary overlays
   * will be closed.
   *
   * @param options
   * * `primary` Boolean indicating whether to close all primary overlays. Defaults to **false**.
   * * `secondary` Boolean indicating whether to close all secondary overlays. Defaults to **true**.
   * @returns Number of overlays closed.
   */
  async closeAll({ primary, secondary } = { primary: false, secondary: true }) {
    let closed = 0;

    try {
      if (primary) {
        if (this.location.path(true).includes('(ol:')) {
          await this.router.navigate([{ outlets: { ol: null } }], {
            queryParamsHandling: 'preserve',
          });
          closed++;
        }
      }

      if (secondary) {
        let top = await this.getTop();

        while (!!!top) {
          if ('close' in top) await top.close();
          else await top.dismiss();
          closed++;
          top = await this.getTop();
        }
      }

      return closed;
    } catch (e) {}
  }
}
