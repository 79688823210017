<ion-app>
  <ion-menu contentId="main" side="end">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ user.name || 'Menu' }}
        </ion-title>

        <ion-buttons slot="end">
          <ion-button color="medium" (click)="openSettings(); menuCtrl.close()">
            <ion-icon name="settings"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-menu-toggle>
        <ion-item
          *ngIf="user.roles?.includes('admin')"
          id="admin-panel-button"
          [button]="true"
          color="tertiary"
          routerLink="/admin"
          routerDirection="forward"
        >
          <ion-label>Admin Panel</ion-label>
          <ion-icon slot="end" name="construct"></ion-icon>
        </ion-item>

        <ion-item
          *ngIf="user.plan === 'free'"
          id="go-premium-button"
          [button]="true"
          color="tertiary"
          class="ion-hide-md-up"
          (click)="goToPlans()"
        >
          <ion-label>GO PREMIUM</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-item
        *ngFor="let b of menuBtns; trackBy: trackByLabel"
        class="menu-button"
        [disabled]="b.disabled"
        [hidden]="b.hidden || (b.hidden && b.hidden)"
        [button]="true"
        (click)="menuBtnClickEvent(b.press)"
      >
        <ion-icon *ngIf="user.plan === 'premium'" [name]="b.icon"></ion-icon>
        <ion-label>{{ b.label }}</ion-label>
      </ion-item>

      <div id="adminGhostBtn" #adminGhostBtn></div>
    </ion-content>

    <ion-footer class="ion-no-border">
      <ion-note color="dark">{{ copyrightInfo }}</ion-note>
      <ion-note color="medium">Version: {{ app.version }}</ion-note>
    </ion-footer>
  </ion-menu>

  <ion-router-outlet id="main"> </ion-router-outlet>

  <ion-router-outlet [hidden]="!router.canShowOverlayIonRouterOutlet" class="overlay-router-outlet" name="ol">
    <div class="overlay-backdrop" tabindex="-1" (click)="overlayBackdropClickEvent($event)"></div>
  </ion-router-outlet>

  <div id="bottomBar">
    <div id="deviceOffline" *ngIf="device.network?.connected === false">
      Device Offline! Check your Wi-FI or data connection. Functionality will be severely limited until you connect to
      the internet.
    </div>
  </div>
</ion-app>
