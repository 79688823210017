import { Injectable } from '@angular/core';
import { FollowDocument } from 'utils/types';
import { determineEntityFromId, extractPKID } from 'utils/functions/id';
import { Timestamp, ArrayRemove, ArrayAdd, DbService } from './db.service';
import { UserService } from './user.service';
import { pluck } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FollowsService {
  ids: string[] = [];
  unfollowEvents$ = new Subject<string>();
  follows$ = this.user.data$.pipe(pluck('follows'));

  constructor(private user: UserService, private db: DbService) {
    this.follows$.subscribe((follows) => {
      const all: string[] = [];
      for (const key in follows) all.push(...follows[key]);
      this.ids = all;
    });
  }

  isFollowed(objectId: string) {
    return this.ids.includes(objectId);
  }

  private buildPath(type: string, associatedEntityId: string) {
    return `${this.user.documentPath}/follows:${type}s/flw:${associatedEntityId}`;
  }

  create(associatedEntityPath: string): FollowDocument {
    const timestamp = Timestamp(),
      associatedEntityId = extractPKID(associatedEntityPath),
      type = determineEntityFromId(associatedEntityId),
      path = this.buildPath(type, associatedEntityId),
      id = extractPKID(path);

    return {
      created: timestamp,
      object: 'follow',
      id,
      path,
      type,
      userUID: this.user.uid,
      associatedEntityId,
      associatedEntityPath,
      metadata: {
        history: ArrayAdd({ type: 'create', timestamp }) as any,
      },
    };
  }

  async unfollow(associatedEntityId: string) {
    const timestamp = Timestamp(),
      type = determineEntityFromId(associatedEntityId);

    await Promise.all([
      this.user.update({ follows: { [`${type}s`]: ArrayRemove(associatedEntityId) as any } }),
      this.db.updateDoc(this.buildPath(type, associatedEntityId), {
        lastUpdated: timestamp,
        history: ArrayAdd({ type: 'delete', timestamp }),
        userUID: null,
      }),
    ]);
    this.unfollowEvents$.next(associatedEntityId);
  }

  async follow(associatedEntityPath: string) {
    const follow = this.create(associatedEntityPath),
      followCollection = `${follow.type}s`;
    await Promise.all([
      this.user.update({ follows: { [followCollection]: ArrayAdd(follow.associatedEntityId) as any } }),
      this.db.updateDoc(follow.path, follow),
    ]);
  }
}
