import { Injectable } from '@angular/core';
import { EpisodeDocument } from 'utils/types';
import { differenceInMinutes } from 'date-fns';
import { combineLatest, merge, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { RSSService } from 'src/app/rss/rss.service';
import { FollowsService } from '../@core/services/follows.service';
import { DbService } from '../@core/services/db.service';
import { wait } from 'beautilities';
import { determineEntityFromId } from 'utils/functions';

@Injectable({ providedIn: 'root' })
export class LatestService {
  list: EpisodeDocument[] = [];
  private lastChecked: { [key: string]: Date } = {};

  constructor(private rss: RSSService, private follows: FollowsService, private db: DbService) {
    this.init();
  }

  private async init() {
    merge(
      combineLatest([this.follows.follows$, timer(0, 1000 * 60 * 30)]).pipe(map(([follows, int]) => follows.shows))
      // combineLatest([this.follows.follows$, timer(0, 1000 * 60 * 60)]).pipe(map(([follows, int]) => follows.persons))
    ).subscribe(async (ids) => {
      const now = new Date();
      for (const id of ids) {
        const object = determineEntityFromId(id);

        if (
          object === 'show' &&
          (!(id in this.lastChecked) || Math.abs(differenceInMinutes(now, this.lastChecked[id])) >= 10)
        ) {
          let latestEpisode = await this.rss.getLatestEpisode(id);
          if (!latestEpisode) {
            await wait(3);
            latestEpisode = await this.rss.getLatestEpisode(id);
          }
          if (latestEpisode) {
            latestEpisode['followType'] = 'show';
            if (this.list.findIndex((x) => x.pubDate === latestEpisode.pubDate) === -1) this.list.push(latestEpisode);
            this.lastChecked[id] = now;
          }
        }

        // TODO: figure out way to search episode with person X using the person ID???
        // else if (object === 'person' && (!(id in this.lastChecked) || Math.abs(differenceInMinutes(now, this.lastChecked[obj.id]))) >= 10)
        //     for (const e of (await lastValueFrom(this.db.searchByTerm$(obj._name, { maxEpisodes: 1, maxShows: 1, object: ['episode'] })))['episodes'] as EpisodeDocument[])
        //         if (this.list.findIndex(x => x.pubDate === e.pubDate) === -1) {
        //             e['followType'] = 'person';
        //             this.list.push(e);
        //             this.lastChecked[obj.id] = now;
        //         }
      }

      this.list.sort((a, b) => (b.pubDate < a.pubDate ? -1 : a.pubDate > b.pubDate ? 1 : 0));
    });

    this.follows.unfollowEvents$.subscribe((id) => {
      const displayedItemIndex = this.list.findIndex((x) => x.id === id);
      if (displayedItemIndex !== -1) this.list.splice(displayedItemIndex, 1);
    });
  }
}
