import { Injectable, Injector } from '@angular/core';
import { Plugins, PermissionType } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { DeviceService } from './device.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { UserService } from './user.service';
import { first, map, pluck } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

const { PushNotifications, Permissions } = Plugins;

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private device: DeviceService, private injector: Injector, private user: UserService) {
    user.data$.pipe(first()).subscribe(() => {
      this.init();
    });
  }

  async getPermissionState() {
    return (await Permissions.query({ name: PermissionType.Notifications })).state;
  }

  init() {
    if (this.device.platform === 'web' && window && 'Notification' in window) return this.initWeb();
    else return this.initNative();
  }

  async openRequestPermissionsWithPrompt() {
    let permissionState: NotificationPermission;
    const dialog = await this.injector.get(AlertController).create({
      id: 'RequestPermissionsPrompt',
      cssClass: 'alert request-permissions',
      header: 'Receive Notifications?',
      message:
        'PrestoKast notifications will keep up to date with the latest episodes of your favourite shows, and more.',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'close-btn',
        },
        {
          text: 'Continue',
          handler: () => {
            Notification.requestPermission().then((state) => {
              permissionState = state;
              dialog.dismiss();
            });
            return false;
          },
        },
      ],
    });
    await dialog.present();
    await dialog.onDidDismiss();
    return permissionState || null;
  }

  private async initNative() {
    try {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      await PushNotifications.requestPermission();

      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (e) => console.error(e));
    } catch (e) {
      console.error(e);
    }
  }

  private async initWeb() {
    try {
      if ((await this.getPermissionState()) === 'prompt') await this.openRequestPermissionsWithPrompt();
      if ((await this.getPermissionState()) === 'granted') {
        const afm = this.injector.get(AngularFireMessaging);
        await afm.usePublicVapidKey(environment.firebaseConfig.publicVapidKey);
        const notificationToken = await lastValueFrom(afm.getToken);
        if (!notificationToken) throw 'FCM notification token was not returned.';
        if (this.user.deviceSettings.notificationToken !== notificationToken)
          this.user.updateDeviceData({ notificationToken });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
