import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { StatusBarPlugin } from '@capacitor/core';
import { Autostart } from '@ionic-native/autostart';
import { BackgroundMode, BackgroundModeOriginal } from '@ionic-native/background-mode';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceService } from './device.service';

@Injectable({ providedIn: 'root' })
export class AppService {
  version = `${environment.version.number}${environment.version.suffix}`;
  releaseDate = environment.version.releaseDate;
  private statusBar: StatusBarPlugin;
  private backgroundMode: BackgroundModeOriginal;
  private autostart: Autostart;
  click$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private device: DeviceService,
    private injector: Injector
  ) {}

  async enableFullscreen() {
    try {
      return this.device.platform === 'web'
        ? this.document.documentElement.requestFullscreen({ navigationUI: 'hide' })
        : (this.statusBar || (this.statusBar = (await import('@capacitor/core')).Plugins.StatusBar)).hide();
    } catch (e) {
      console.error(e);
    }
  }

  async disableFullscreen() {
    try {
      return this.device.platform === 'web' ? this.document.exitFullscreen() : this.statusBar.hide();
    } catch (e) {}
  }

  enableBackgroundMode() {
    return (this.backgroundMode || (this.backgroundMode = this.injector.get(BackgroundMode))).enable();
  }

  disableBackgroundMode() {
    return (this.backgroundMode || (this.backgroundMode = this.injector.get(BackgroundMode))).disable();
  }

  enableAutostart() {
    return (this.autostart || (this.autostart = this.injector.get(Autostart))).enable();
  }

  disableAutostart() {
    return (this.autostart || (this.autostart = this.injector.get(Autostart))).disable();
  }
}
