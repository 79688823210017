import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { environment } from 'src/environments/environment';
import { ErrorService } from './services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  es: ErrorService;

  async handleError(error: any) {
    if (environment.production) {
      try {
        if (!this.es) this.es = this.injector.get(ErrorService);

        await this.es.logError({
          stack: (await StackTrace.fromError(error))
            .splice(0, 20)
            .map((sf) => sf.toString())
            .join('\n'),
          error,
        });
      } catch (e) {
        console.error(e);
      }

      if (/Loading chunk [\d]+ failed/.test(error.message)) window.location.reload();
    }

    throw error;
  }
}
