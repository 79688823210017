import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, QueryFn } from '@angular/fire/firestore';
import { map, finalize, switchMap, first, tap } from 'rxjs/operators';
import { Observable, lastValueFrom } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  EpisodeDocument,
  PersonDocument,
  ShowDocument,
  SearchShowsResponse,
  SearchEpisodesResponse,
  SubscriptionPlanId,
  Collection,
} from 'utils/types';
import { HttpClient } from '@angular/common/http';
import { objToQueryString } from 'beautilities';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({ providedIn: 'root' })
export class DbService {
  userPlan: SubscriptionPlanId;

  constructor(
    public afs: AngularFirestore,
    public afcs: AngularFireStorage,
    private http: HttpClient,
    private aff: AngularFireFunctions
  ) {}

  /* -------------------------------------- Core Properties - START -------------------------------------- */
  getDoc(path: string) {
    return lastValueFrom(this.doc$(path).pipe(first()));
  }

  private write(path: string, data: any) {
    console.log('write', { path, data });
    if (path.includes('undefined'))
      console.warn(`Document write to path ${path} was unsuccessful: 'undefined' is not allowed in the path name`);
    else return this.afs.doc(path).set(data, { merge: true });
  }

  updateDoc(path: string, data) {
    // console.log({ path, data })
    if (!data.lastUpdated && !data.created) data.lastUpdated = Timestamp();
    return this.write(path, data);
  }

  uploadFile(path: string, data, customMetadata?: { [key: string]: string }): Promise<string> {
    return new Promise((resolve, reject) =>
      this.afcs
        .upload(path, data)
        .snapshotChanges()
        .pipe(finalize(async () => resolve(await lastValueFrom(this.afcs.ref(path).getDownloadURL()))))
        .subscribe()
    );
  }

  deleteFile(path: string) {
    return this.afcs.ref(path).delete();
  }

  batch() {
    return this.afs.firestore.batch();
  }

  ref(path: string) {
    return path.split('/').length % 2 === 1 ? this.afs.collection(path).ref : this.afs.doc(path).ref;
  }

  /* ------------------- Observables - START ------------------- */

  doc$(path: string): Observable<any> {
    console.log('read doc', path);
    return this.afs.doc(path).valueChanges();
  }

  collection$(path: string, query?: QueryFn): Observable<any[]> {
    console.log('read collection', path);
    return this.afs.collection(path, query).valueChanges();
  }

  collectionGroup$(path: string, query?: QueryFn): Observable<any[]> {
    console.log('read collection group', path);
    return this.afs.collectionGroup(path, query).valueChanges();
  }
  /* ------------------- Observables - END ------------------- */

  /* -------------------------------------- Core Properties - END -------------------------------------- */

  /* ------------------- Methods - START ------------------- */

  searchEpisodes(query: string, options?: { limit?: number; props?: string[] }) {
    return this.http.get(
      `https://us-central1-prestokast.cloudfunctions.net/searchEpisodes/?${objToQueryString(
        { query, ...options },
        undefined,
        { arraySeparator: ',' }
      )}`
    ) as Observable<SearchEpisodesResponse>;
  }

  searchShows(query: string, options?: { limit?: number; props?: string[] }) {
    return this.http.get(
      `https://us-central1-prestokast.cloudfunctions.net/searchShows/?${objToQueryString({ query, ...options }, '&', {
        arraySeparator: ',',
      })}`
    ) as Observable<SearchShowsResponse>;
  }

  async getEditorsPicks() {
    return {
      shows: await lastValueFrom(
        this.collection$(Collection.Shows, (ref) => ref.where('featured', '==', true)).pipe(first())
      ),
      episodes: await lastValueFrom(
        this.collection$(Collection.Episodes, (ref) => ref.where('featured', '==', true)).pipe(first())
      ),
    };
  }

  async getEpisode(id: string, options?: { noCache?: boolean; props?: string[] }) {
    console.log(id);
    if (this.userPlan === 'free')
      return lastValueFrom(
        this.http.get(
          `https://us-central1-prestokast.cloudfunctions.net/getEpisode/?${objToQueryString(
            {
              id,
              ...options,
            },
            undefined,
            { arraySeparator: ',' }
          )}`
        )
      ) as Promise<EpisodeDocument>;

    return lastValueFrom(
      // Collection Group is used for single gets to allow querying to reach user episodes and private episodes.
      this.collectionGroup$(Collection.Episodes, (ref) => ref.where('id', '==', id)).pipe(
        switchMap(async ([episode]) => {
          if (options?.props?.length)
            for (const p of options.props) {
              if (episode.showId && p === 'show') {
                episode.show = await this.getShow(episode.showId, {
                  props: options.props.includes('persons') ? ['persons'] : undefined,
                });
                episode.props = (episode.props || []).concat('show');
              } else if (episode.entities?.persons?.length && p === 'persons') {
                const persons = episode.entities.persons as any[];
                for (let i = 0; i < persons.length; i++) persons[i] = (await this.getPerson(persons[i])) || persons[i];
                episode.entities.persons = persons;
                episode.props = (episode.props || []).concat('persons');
              }
            }
          console.log(episode);
          episode.metadata.pulledFromDb = Timestamp();
          return episode as EpisodeDocument;
        }),
        first()
      )
    );
  }

  async getShow(identifier: string, options?: { noCache?: boolean; props?: string[] }) {
    if (this.userPlan === 'free')
      return lastValueFrom(
        this.http.get(
          `https://us-central1-prestokast.cloudfunctions.net/getShow/?${objToQueryString(
            {
              identifier,
              ...options,
            },
            undefined,
            { arraySeparator: ',' }
          )}`
        )
      ) as Promise<ShowDocument>;

    const show: ShowDocument = identifier.startsWith('shw_')
      ? await this.getDoc(`${Collection.Shows}/${identifier}`)
      : await lastValueFrom(
          this.collection$(Collection.Shows, (ref) => ref.where('feedUrl', '==', identifier)).pipe(
            map(([show]) => show),
            first()
          )
        );

    if (options?.props?.length)
      for (const p of options.props) {
        if (p === 'persons' && show.entities?.persons?.length) {
          const persons = show.entities.persons as any[];
          for (let i = 0; i < persons.length; i++) persons[i] = (await this.getPerson(persons[i])) || persons[i];
          show.entities.persons = persons;
          show.props = (show.props || []).concat('persons');
        } else if (p === 'episodes' && show.episodeCount) {
          let episodes = await this.getEpisodes(show.id, options.props);
          if (options.props.includes(Collection.Shows))
            episodes = episodes.map((e) => {
              e.show = show;
              return e;
            });
          show.episodes = episodes;
        }
      }

    show.metadata.pulledFromDb = Timestamp();
    return show;
  }

  getEpisodes(showId: string, props?: string[]) {
    const timestamp = Timestamp(),
      includeShow = props?.includes('show'),
      includePersons = props?.includes('persons');
    return lastValueFrom(
      this.collection$(Collection.Episodes, (ref) => ref.where('showId', '==', showId).orderBy('pubDate', 'desc')).pipe(
        switchMap(async (episodes: EpisodeDocument[]) => {
          const show = includeShow
            ? await this.getShow(showId, { props: includePersons ? ['persons'] : undefined })
            : undefined;
          return await Promise.all(
            episodes.map(async (episode) => {
              if (includeShow) episode.show = show;
              if (includePersons && episode.entities?.persons) {
                const persons = episode.entities.persons as any[];
                for (let i = 0; i < persons.length; i++) persons[i] = (await this.getPerson(persons[i])) || persons[i];
                episode.entities.persons = persons;
              }
              episode.metadata.pulledFromDb = timestamp;
              if (props) episode.props = props;
              return episode;
            })
          );
        }),
        first()
      )
    );
  }

  getPerson(identifier: string, options?: { bypassCache?: boolean }): Promise<PersonDocument | PersonDocument[]> {
    return this.userPlan === 'free'
      ? lastValueFrom(
          this.http.get(
            `https://us-central1-prestokast.cloudfunctions.net/getPerson/?${objToQueryString(
              { identifier, ...options },
              undefined,
              { arraySeparator: ',' }
            )}`
          )
        )
      : identifier.startsWith('psn_')
      ? this.getDoc(`persons/${identifier}`)
      : lastValueFrom(this.collection$('persons', (r) => r.where('name', '==', identifier)).pipe(first()));
  }

  async incrementDocClickCount(documentPath: string) {
    try {
      await this.afs.doc(documentPath).update({ clickCount: IncrementValue(1) });
      return true;
    } catch (error) {
      return false;
    }
  }
  /* ------------------- Methods - END ------------------- */
}

export const TimestampSentinel = firebase.firestore.FieldValue.serverTimestamp;
export const DeleteField = firebase.firestore.FieldValue.delete;
export const ArrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const ArrayAdd = firebase.firestore.FieldValue.arrayUnion;
export const IncrementValue = firebase.firestore.FieldValue.increment;

export function Timestamp(): string;
export function Timestamp(asDate: true): Date;
export function Timestamp(asDate?: true) {
  return asDate ? new Date() : new Date().toISOString();
}
