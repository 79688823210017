import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ItemButtonComponent } from './item-button.component';

@NgModule({
  declarations: [ItemButtonComponent],
  imports: [CommonModule, IonicModule],
  exports: [ItemButtonComponent],
  providers: [],
})
export class ItemButtonModule {}
