import { Injectable, Injector } from '@angular/core';
import { Router, UrlTree, NavigationEnd, NavigationExtras } from '@angular/router';
import { map, filter, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ROUTES } from '../../app-routing.module';
import { NavController, Platform } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { OverlaysService } from './overlays.service';
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { RedirectService } from './redirect.service';

export interface NavOptions extends NavigationOptions {
  withPrefix?: boolean;
  closeOverlays?: boolean;
}

@Injectable({ providedIn: 'root' })
export class RouterService {
  url: string;
  url$ = new Subject<string>();
  error: boolean;
  guardsDown = false;
  navigationCount = 0;
  isBackAtStartingUrl: boolean;
  startingPath: string;
  showOverlayIonRouterOutlet: boolean;

  constructor(
    public ngRouter: Router,
    public location: Location,
    private navCtrl: NavController,
    private overlays: OverlaysService,
    private auth: AuthService,
    public redirect: RedirectService,
    private platform: Platform,
    private injector: Injector
  ) {
    this.init();
  }

  private init() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.navCtrl.back();
    });

    this.location.subscribe((s) => (this.isBackAtStartingUrl = s.state?.navigationId === 1));

    this.ngRouter.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        map(() => this.ngRouter.url)
      )
      .subscribe((url) => {
        this.navigationCount++;
        this.url$.next(url);
        this.url = url;
        if (!this.startingPath) this.startingPath = url;
      });
  }

  navigate(commands: any[], extras?: NavigationExtras) {
    return this.ngRouter.navigate(commands, extras);
  }

  get canShowOverlayIonRouterOutlet() {
    return this.url?.includes('ol:');
  }

  get canGoBack() {
    return this.navigationCount > 1 || this.url?.includes('(ol:');
  }

  path(includeHash?: true) {
    return this.location.path(includeHash);
  }

  replaceState(path: string, options?: { query?: string; state?: any; emitEvent?: boolean }) {
    this.location.replaceState(path, options?.query, options?.state);
    if (options?.emitEvent) this.url$.next(this.path(true));
  }

  appendState(str: string, options?: { query?: string; state?: any; emitEvent?: boolean }) {
    const currentPath = this.path(true);
    this.replaceState(
      currentPath.endsWith(')') && !str.endsWith(')') ? `${currentPath.slice(0, -1)}${str})` : `${currentPath}${str}`,
      options
    );
  }

  removeFromState(str: string, emitEvent?: boolean) {
    this.replaceState(
      this.path(true)
        .split('')
        .reverse()
        .join('')
        .replace(str.split('').reverse().join(''), '')
        .split('')
        .reverse()
        .join('')
    );
    if (emitEvent) this.url$.next(this.path(true));
  }

  /**
   * Navigate to a route using an absolute path. If the user is logged in, the '&' prefix will be added (for the tabs module).
   *
   * @param path An absolute path. It should begin with a forward slash, but if not one will be added automatically.
   * @param direction Direction of this navigation to ensure the correct animation is used. [Default: 'forward']
   * @param extras Extra options to pass to the underlying Angular router and Ionic Animation Service.
   * The `withPrefix` parameter refers to the 'u' which is added to load the tabs.
   * @returns Promise that resolves when navigation completes.
   */
  async go(
    path: string,
    direction: 'back' | 'forward' | 'root' = 'root',
    extras: NavOptions = {
      withPrefix: true,
      closeOverlays: true,
    }
  ) {
    console.trace(path, direction, extras);
    if (!extras.queryParamsHandling) extras.queryParamsHandling = 'merge';

    if (this.redirect.redirectTo) {
      path = this.redirect.redirectTo;
      this.redirect.redirectTo = null;
    } else {
      if (path.includes('/u/')) path = path.replace('/u/', '/');

      if (extras.closeOverlays) await this.overlays.closeAll();
      if (!path.startsWith('/')) path = `/${path}`;
      if (this.auth.userLoggedIn && extras.withPrefix) path = `/u${path}`;
    }

    switch (direction) {
      case 'back':
        return this.navCtrl.navigateBack(path, extras);
      case 'forward':
        return this.navCtrl.navigateForward(path, extras);
      case 'root':
        return this.navCtrl.navigateRoot(path, extras);
    }
  }

  back(overlayId?: string) {
    return overlayId
      ? this.injector.get(OverlaysService).close(overlayId)
      : window
      ? window?.history.back()
      : this.navCtrl.back();
  }

  isActive(url: string | UrlTree, exact?: boolean) {
    return this.ngRouter.isActive(url, exact);
  }

  isNavHistory(): boolean {
    const current = this.ngRouter.getCurrentNavigation();
    return !!(current || (current && current.id <= 1));
  }

  isOverlayOpen(objectId: string, returnMatchArray?: boolean) {
    const res = this.path(true).match(new RegExp(`#\\(ol:([a-z]*)=${objectId}([a-zA-Z0-9&=_]*)\\)`));
    return returnMatchArray ? res : res?.shift();
  }

  resetConfig() {
    return this.ngRouter.resetConfig(ROUTES);
  }
}
