import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { PROXY } from 'configs/proxy';

const prependProxy = (url: string, proxy = PROXY.heroku) =>
  `${proxy}/${proxy.includes('firebaseapp') ? url.replace('://', ':/') : url}`;
@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request,
      alteredURL: string;

    if (request.urlWithParams.includes('noProxy=true'))
      req = request.clone({ params: request.params.delete('noProxy') });
    else {
      if (!request.url.includes('prestokast.cloudfunctions')) alteredURL = prependProxy(request.url);
      else {
        if (environment.BYPASS_SERVER_CACHE)
          alteredURL = `${request.url}${request.url.includes('?') ? '' : '?'}&noCache=true`;
      }

      if (alteredURL)
        req = request.clone({
          url: alteredURL,
          headers: new HttpHeaders('tsak-otserp'),
        });
    }

    // return next.handle(req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
          // return event.clone({
          //   body: typeof event.body === 'string' ? JSON.parse(event.body) : event.body
          // });
        }
      })
    );
  }
}
