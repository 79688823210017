import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DividerNoLineDirective,
  DividerSmallDirective,
  DividerInsetDirective,
  DividerComponent,
} from './divider.component';

@NgModule({
  declarations: [DividerComponent, DividerNoLineDirective, DividerSmallDirective, DividerInsetDirective],
  imports: [CommonModule],
  exports: [DividerComponent, DividerNoLineDirective, DividerSmallDirective, DividerInsetDirective],
  providers: [],
})
export class DividerModule {}
